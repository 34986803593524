import React from 'react';
import cx from 'classnames';
import dynamic from 'next/dynamic';

import styles from './index.module.scss';

const Typography = dynamic(() => import('#app/UI/atoms/typography-updated'));

const statisticsData = [
  {
    metric: '38%',
    title: 'Es el déficit de talentos',
    description: (
      <>
        con habilidades <br /> digitales en LATAM
      </>
    ),
    source: 'Fuente: Banco Interamericano de Desarrollo',
    color: '#1D5BBC',
    bgColor: '#BFE1FC'
  },
  {
    metric: '4 de 5',
    title: 'Alumnos de Bachillerato no saben qué estudiar',
    description: 'afrontando el proceso con incertidumbre.',
    source: 'Fuente: Salón de Orientación Universitaria Onitou',
    color: '#E57B43',
    bgColor: '#FFEFE7'
  },
  {
    metric: '18%',
    title: 'Es el porcentaje de estudiantes mujeres',
    description: 'en tecnologías de la información y comunicación en LATAM',
    source: 'Fuente: CEPAL & ONU Mujeres',
    color: '#756DC8',
    bgColor: '#DDDAFF'
  }
];

export const StatisticsCard = ({
  metric,
  title,
  description,
  source,
  color,
  bgColor
}) => {
  return (
    <div className={styles.cardItem} style={{ background: bgColor }}>
      <Typography
        className={cx(styles.metric, 'rockone')}
        tag="p"
        style={{ color: color }}
      >
        {metric}
      </Typography>
      <div className={styles.mainText}>
        <Typography
          className={cx(styles.cardTitle, 'rockone')}
          tag="p"
          style={{ color: color }}
        >
          {title}
        </Typography>
        <Typography className={cx(styles.description, 'rockone')} tag="p">
          {description}
        </Typography>
      </div>
      <Typography className={cx(styles.source)} tag="p">
        {source}
      </Typography>
    </div>
  );
};

const Statistics = () => {
  return (
    <section className={styles.container}>
      <Typography className={cx(styles.title)} tag="h2">
        Buscamos generar más y mejores <br className={styles.desktop} />{' '}
        <span className={styles.mainColor}>
          oportunidades para las futuras generaciones
        </span>
      </Typography>

      <div className={styles.cards}>
        {statisticsData.map((statistic, idx) => (
          <StatisticsCard {...statistic} key={`statistic-item-${idx}`} />
        ))}
      </div>
    </section>
  );
};

export default Statistics;
